import React, { FC, useContext, useMemo } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import { IProductCard } from '@shared/types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import getFamily from 'utils/getFamily';
import getLabel from 'utils/getLabel';
import './ProductCard.scss';
import transformProductSize from 'utils/stringUtils/transformProductSize';
import AddToCartButton from 'components/AddToCartButton';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import { BazaarVoiceInlineRating } from '@phx-husky/bazaarvoice-components';
import ProductCardTitle from './ProductCardTitle/ProductCardTitle';

const ProductCard: FC<IProductCard> = ({
  discount,
  link,
  title,
  shortDescription,
  images,
  tags,
  cardLink,
  quantity,
  productSize,
  productScent,
  packagingType,
  cardBenefits,
  cardDescription,
  bundleProductsQuantity,
  price,
  oldPrice,
  cardImage,
  skuId,
  shopifyId,
  isShopify,
  lang,
  cartProductsData,
  variantsQuantity,
  hasChevron = false,
  productType,
  isBundles,
  titlePlaceholder,
  useRedirects,
  usePriceSpider,
  ean,
  isTitleLong,
  isEanProductId,
  isSlideItem,
}) => {
  const { purchaseSettings } = useContext(CartContext);
  const {
    saveLabel,
    itemsInBundlePlaceholder,
    oldPriceLabel,
    currentPriceLabel,
    currentPriceLabelProduct,
    productCardVariantsLabel,
  } = purchaseSettings;
  const labelTagText = getLabel(tags);
  const saveLabelText = discount ? saveLabel?.replace(/%amount%/i, String(discount)) : null;
  const label = labelTagText || saveLabelText;
  const family = getFamily(tags);
  const to = cardLink?.length ? cardLink[0].url : link;
  const description = isBundles
    ? cardDescription
    : isSlideItem
    ? shortDescription
    : cardDescription || shortDescription;
  const imageToShow = cardImage?.[0]?.properties || images?.[0]?.properties;

  const itemsInBundleString = itemsInBundlePlaceholder?.replace(
    /%quantity%/i,
    bundleProductsQuantity
  );

  const isProductCard = productType === 'product';
  const currentPriceLabelValue = isProductCard ? currentPriceLabelProduct : currentPriceLabel;

  const bundlePrice = useMemo(
    () =>
      price ? (
        <div className="product-card__stock-info">
          {variantsQuantity && (
            <div className="stock-info__variants">
              {`${variantsQuantity} ${productCardVariantsLabel}`}
            </div>
          )}
          <div className="stock-info__price">
            {oldPrice && (
              <div className="stock-info__price--old">
                <span className="sr-only">{oldPriceLabel}</span>
                {oldPrice || price}
              </div>
            )}
            <div className="stock-info__price--new">
              <span className="sr-only">{currentPriceLabelValue}</span>
              {price}
            </div>
          </div>
          {bundleProductsQuantity && (
            <div className="stock-info__quantity">{itemsInBundleString}</div>
          )}
        </div>
      ) : null,
    [
      price,
      variantsQuantity,
      productCardVariantsLabel,
      oldPrice,
      bundleProductsQuantity,
      oldPriceLabel,
      itemsInBundleString,
    ]
  );

  const productPrice = useMemo(
    () =>
      price ? (
        <div className="product-card__stock-info">
          <div className="stock-info__price">
            <div className="stock-info__price--new stock-info__price--new-product">
              <span className="sr-only">{currentPriceLabelValue}</span>
              {price}
            </div>
          </div>
          {variantsQuantity && (
            <div className="stock-info__variants">
              {variantsQuantity > 1 ? `${variantsQuantity} ${productCardVariantsLabel}` : ''}
            </div>
          )}
        </div>
      ) : null,
    [price, currentPriceLabelValue, variantsQuantity, productCardVariantsLabel]
  );

  const titleToShow =
    titlePlaceholder && productType === 'variant'
      ? titlePlaceholder
          .replace(/%title%/i, title)
          .replace(/%productSize%/i, productSize || '')
          .replace(/%packagingType%/i, packagingType || '')
          .replace(/%productScent%/i, productScent || '')
      : title;

  const bvProductId = isEanProductId && ean ? ean : skuId || ean;

  return (
    <div
      className={classnames('product-card', {
        'product-card--has-chevron': hasChevron,
        [`product-card--${family?.color?.label}-family`]: Boolean(family),
        'product-card--title-long-word': isTitleLong,
      })}
      data-testid="product-card"
    >
      <span className="product-card__line" />
      <div className="product-card__text">
        {titleToShow && !useRedirects ? (
          <Link to={to} className="product-card__link">
            <ProductCardTitle titleToShow={titleToShow} />
          </Link>
        ) : null}
        {bvProductId ? <BazaarVoiceInlineRating productId={bvProductId} seo={false} /> : null}
        {titleToShow && useRedirects ? (
          <a href={to} className="product-card__link product-card__link--redirect">
            <ProductCardTitle titleToShow={titleToShow} />
          </a>
        ) : null}
        {quantity ? (
          <p className="product-card__size">
            {transformProductSize({
              quantity,
              productSize,
              packagingType,
            })}
          </p>
        ) : null}
        {description ? (
          <DangerouslySetInnerHtml className="product-card__description" html={description} />
        ) : null}
        {cardBenefits ? (
          <DangerouslySetInnerHtml className="product-card__benefits" html={cardBenefits} />
        ) : null}

        {label ? <div className="product-card__label">{label}</div> : null}
      </div>
      {isProductCard ? productPrice : bundlePrice}
      {skuId && shopifyId ? (
        <div className="product-card__buy-button product-card__buy-button--dark-theme">
          <AddToCartButton
            isShopify={isShopify}
            lang={lang}
            cartProductsData={cartProductsData}
            productskuId={skuId}
            productShopifyId={shopifyId}
            buttonVariant="secondary"
            productTitle={title}
          />
        </div>
      ) : null}
      {ean && !!usePriceSpider && isBundles && (
        <div className="product-card__buy-button product-card__buy-button--dark-theme">
          <div className="ps-widget" ps-sku={ean} />
        </div>
      )}
      <div className="product-card__image">
        {imageToShow ? (
          <GatsbyImage
            image={imageToShow.image}
            objectFit="contain"
            objectPosition="50% 100%"
            className="product-card__image-img"
            alt={imageToShow.imageAlt}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProductCard;
